@font-face {
  font-family: "thumbzone-icon";
  src: url("/fonts/thumbzone-icon/thumbzone-icon.eot");
  src:
    url("/fonts/thumbzone-icon/thumbzone-icon.eot#iefix") format("embedded-opentype"),
    url("/fonts/thumbzone-icon/thumbzone-icon.ttf") format("truetype"),
    url("/fonts/thumbzone-icon/thumbzone-icon.woff") format("woff"),
    url("/fonts/thumbzone-icon/thumbzone-icon.svg#thumbzone-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "thumbzone-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tz-icon-ai-minimal:before {
  content: "\e940";
}
.tz-icon-up-diamond:before {
  content: "\e93f";
}
.tz-icon-notebook-search:before {
  content: "\e93e";
}
.tz-icon-copy:before {
  content: "\e93d";
}
.tz-icon-lock:before {
  content: "\e93c";
}
.tz-icon-user:before {
  content: "\e93b";
}
.tz-icon-logout:before {
  content: "\e93a";
}
.tz-icon-done:before {
  content: "\e939";
}
.tz-icon-flag-fill:before {
  content: "\e937";
}
.tz-icon-flag:before {
  content: "\e938";
}
.tz-icon-edit:before {
  content: "\e936";
}
.tz-icon-ai:before {
  content: "\e900";
}
.tz-icon-archive:before {
  content: "\e901";
}
.tz-icon-arrow-left:before {
  content: "\e902";
}
.tz-icon-arrow-right:before {
  content: "\e903";
}
.tz-icon-billing:before {
  content: "\e91b";
}
.tz-icon-board:before {
  content: "\e904";
}
.tz-icon-card:before {
  content: "\e905";
}
.tz-icon-chevron-left:before {
  content: "\e931";
}
.tz-icon-chevron-right:before {
  content: "\e932";
}
.tz-icon-circle-add:before {
  content: "\e907";
}
.tz-icon-circle-done:before {
  content: "\e908";
}
.tz-icon-circle-information:before {
  content: "\e909";
}
.tz-icon-circle-question:before {
  content: "\e90b";
}
.tz-icon-circle-remove:before {
  content: "\e90a";
}
.tz-icon-click:before {
  content: "\e90c";
}
.tz-icon-click-add:before {
  content: "\e929";
}
.tz-icon-dashboard:before {
  content: "\e90d";
}
.tz-icon-delete:before {
  content: "\e90e";
}
.tz-icon-dislike:before {
  content: "\e935";
}
.tz-icon-document:before {
  content: "\e90f";
}
.tz-icon-dotted-menu:before {
  content: "\e910";
}
.tz-icon-email:before {
  content: "\e911";
}
.tz-icon-expand:before {
  content: "\e912";
}
.tz-icon-filter:before {
  content: "\e913";
}
.tz-icon-flow:before {
  content: "\e914";
}
.tz-icon-help-docs:before {
  content: "\e915";
}
.tz-icon-insights:before {
  content: "\e906";
}
.tz-icon-integration:before {
  content: "\e916";
}
.tz-icon-issues:before {
  content: "\e917";
}
.tz-icon-like:before {
  content: "\e934";
}
.tz-icon-notes:before {
  content: "\e918";
}
.tz-icon-notification:before {
  content: "\e933";
}
.tz-icon-notification-box:before {
  content: "\e919";
}
.tz-icon-personas:before {
  content: "\e91a";
}
.tz-icon-plan-card:before {
  content: "\e91c";
}
.tz-icon-plans:before {
  content: "\e91d";
}
.tz-icon-play:before {
  content: "\e91e";
}
.tz-icon-predictions:before {
  content: "\e91f";
}
.tz-icon-product:before {
  content: "\e920";
}
.tz-icon-product-setting:before {
  content: "\e921";
}
.tz-icon-products:before {
  content: "\e922";
}
.tz-icon-prototyping:before {
  content: "\e923";
}
.tz-icon-regenerate:before {
  content: "\e924";
}
.tz-icon-reports:before {
  content: "\e925";
}
.tz-icon-screen-outline:before {
  content: "\e926";
}
.tz-icon-search:before {
  content: "\e927";
}
.tz-icon-setting:before {
  content: "\e928";
}
.tz-icon-support:before {
  content: "\e92a";
}
.tz-icon-tap-hold:before {
  content: "\e92b";
}
.tz-icon-test-plan:before {
  content: "\e92c";
}
.tz-icon-testers:before {
  content: "\e92d";
}
.tz-icon-upload:before {
  content: "\e92e";
}
.tz-icon-users:before {
  content: "\e92f";
}
.tz-icon-workspaces:before {
  content: "\e930";
}
