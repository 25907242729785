@use "./fonts/fonts.scss";
@use "./table.scss";

html {
  background-color: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  a {
    text-decoration: none;
    color: initial;
  }

  textarea {
    resize: none !important;
  }
}

button,
a {
  box-shadow: none !important;
  border-radius: var(--border-radius-size) !important;

  &.warn {
    background-color: #db2a26 !important;
    color: white;
  }

  &.primary-btn {
    box-shadow: none !important;
    color: #00247a !important;
    background-color: var(--primary-color) !important;
  }

  &.support-button {
    position: fixed;
    bottom: 16px;
    right: 16px;
    border: 1px solid #e6e6e6;
    border-radius: 100px !important;
    background-color: var(--primary-color) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  &.ai-btn {
    background: linear-gradient(270deg, rgba(168, 50, 121, 1) 0%, rgba(211, 131, 18, 1) 100%);
  }

  &.left-icon {
    i {
      margin-right: 8px;
      margin-left: -4px;
    }
  }

  &.right-icon {
    i {
      margin-left: 8px;
      margin-right: -4px;
    }
  }

  .mdc-button__label {
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}

mat-form-field {
  .mat-mdc-text-field-wrapper {
    border-left: 1px solid #d5d5d5;
    border-right: 1px solid #d5d5d5;
    border-top: 1px solid #d5d5d5;
    border-top-left-radius: var(--border-radius-size);
    border-top-right-radius: var(--border-radius-size);
  }

  .mat-mdc-select-arrow-wrapper {
    display: none;
  }
}

mat-snack-bar-container {
  &.error-snack {
    .mdc-snackbar__surface {
      background: #d72626 !important;

      .mat-mdc-snack-bar-actions {
        border-radius: var(--border-radius-size);

        span {
          color: white;
        }
      }
    }
  }

  &.success-snack {
    .mdc-snackbar__surface {
      background: #1c7c02 !important;

      .mat-mdc-snack-bar-actions {
        border-radius: var(--border-radius-size);

        span {
          color: white;
        }
      }
    }
  }

  &.info-snack {
    .mdc-snackbar__surface {
      background: #4000ff !important;

      .mat-mdc-snack-bar-actions {
        border-radius: var(--border-radius-size);

        span {
          color: white;
        }
      }
    }
  }

  &.warning-snack {
    .mdc-snackbar__surface {
      background: rgba(255, 215, 64, 0.87) !important;

      .mat-mdc-snack-bar-label {
        color: black;
      }

      .mat-mdc-snack-bar-actions {
        border-radius: var(--border-radius-size);

        span {
          color: black;
        }
      }
    }
  }
}

.mat-mdc-dialog-title {
  &::before {
    display: none !important;
  }
}

.mat-mdc-option span {
  display: flex;
  align-items: center;

  i {
    margin-right: 4px;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}
