@use "./thumbzone-icon.scss";
@use "./albert.scss";

* {
  font-family: var(--font-name) !important;
  letter-spacing: normal !important;
}

i {
  font-size: 1.5rem;
}
